// libraries
import React, { useEffect } from "react";
import { graphql } from "gatsby";
// actions
import { updateHeaderStyle } from "../store/actions/base";
// components
import { DocumentContent } from "../components/DocumentContent";
import { Layout } from "../components/Layout";
// hooks
import { useDispatch } from "react-redux";
// static
import "bootstrap/dist/css/bootstrap.min.css";

export default ({ data }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    updateHeaderStyle(dispatch, false);
  }, []);

  return (
    <Layout data={data.wordpressPage}>
      <DocumentContent data={data.wordpressPage} />
    </Layout>
  );
};

export const postQuery = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      wordpress_id
      type
      title
      content
      yoast {
        title
        metadesc
      }
      polylang_current_lang
      polylang_translations {
        polylang_current_lang
        id
        path
        slug
        status
      }
      acf {
        template_global_hide_title
      }
    }
  }
`;
