// libraries
import React from "react";
import styled from "styled-components";
// components
import { BreadcrumbsCustom } from "../CrumbsWithTitleBlock/BreadcrumbsCustom";
// constants
import { BREAKPOINTS } from "../../settings";
// hooks
import { usePageInfo } from "../../hooks/use-page-info";

export const DocumentContent = ({ data }) => {
  const pageInfo = usePageInfo(data.wordpress_id);
  const hideTitle = data.acf.template_global_hide_title;
  const titleBlockClasses = hideTitle ? 'visually-hidden' : '';

  return (
    <StyledDocumentContent className="block-container custom_container">
      <div className={titleBlockClasses}>
        <BreadcrumbsCustom pageInfo={pageInfo} />
        <h1 dangerouslySetInnerHTML={{ __html: data.title }} />
      </div>

      <div
        className="the_content"
        dangerouslySetInnerHTML={{ __html: data.content }}
      />
    </StyledDocumentContent>
  );
};

const StyledDocumentContent = styled.div`
  &.custom_container {
    padding-bottom: 120px;
    padding-top: 75px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      padding-top: 115px;
      padding-bottom: 0;
    }
  }

  h1 {
    font-weight: bold;
    font-size: 25px;
    line-height: 30px;
    color: #040404;
    margin-bottom: 35px;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 50px;
      line-height: 65px;
      margin-bottom: 50px;
    }
  }

  .the_content {
    width: 100%;
    font-size: 15px;
    line-height: 25px;
    color: #040404;

    @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
      font-size: 20px;
      line-height: 35px;
    }

    p {
      margin-bottom: 5px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        margin-bottom: 10px;
      }
    }

    h2 {
      font-weight: bold;
      font-size: 25px;
      line-height: 30px;
      color: #040404;
      margin-bottom: 15px;
      margin-top: 10px;

      @media (min-width: ${BREAKPOINTS.tablet + "px"}) {
        font-size: 30px;
        line-height: 40px;
      }
    }
  }
`;
